import { useSSRContext } from 'vue'
import type { H3Event } from 'h3'

/**
 * Invoke this composable before any async operation in the setup. Although it
 * should, after the async operation the ssr context is not available anymore.
 *
 * https://github.com/nuxt/nuxt/issues/14269#issuecomment-1397352832
 *
 * As a workaround this composables stores the event and passes it to the
 * useRouteCache.
 *
 * */

export enum CacheTagPrefix {
  Product = 'P',
  Category = 'C',
  Attribute = 'A',
  Brand = 'B',
  View = 'V',
  ContentFull = 'CF',
}

export const useRedisCache = () => {
  const event = import.meta.server ? (useSSRContext()?.event as H3Event) : null

  const route = useRoute()

  const hasContext = () => event?.context.redisCacheContext

  const addTags = (tags: { prefix: CacheTagPrefix; value: string }[]) => {
    hasContext() && event!.context.redisCacheContext!.addTags(tags.map((t) => `${t.prefix}:${t.value}`))
  }

  return {
    emptyQueryString: () => Object.keys(route.query).length === 0,
    addTags,
  }
}
